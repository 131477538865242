import { createBrowserRouter } from "react-router-dom";
import HomePage from "../pages/HomePage";
import Main from "../layout/Main";
import LoginPage from "../pages/LoginPage";
import RegistrationPage from "../pages/RegistrationPage";
import RackbeatPage from "../pages/RackbeatPage";
import EconomicPage from "../pages/EconomicPage";
import DineroPage from "../pages/DineroPage";
import NavisionPage from "../pages/NavisionPage";
import BusinessCentralKundeportalPage from "../pages/BusinessCentralKundeportalPage";
import BusinessCentralPage from "../pages/BusinessCentralPage";
import BusinessCentralPaymentsPage from "../pages/BusinessCentralPaymentsPage";
import EconomicKundeportalPage from "../pages/EconomicKundeportalPage";
import EconomicPaymentsPage from "../pages/EconomicPaymentsPage";
import PricingPage from "../pages/PricingPage";
import PartnersPage from "../pages/PartnersPage";
import DatabehandleraftalePage from "../pages/DatabehandleraftalePage";
import AbonnementsvilkårPage from "../pages/AbonnementsvilkårPage";
import OmOsPage from "../pages/OmOsPage";
import ContactPage from "../pages/ContactPage";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Main />,
        children: [
            {
                path: "/",
                element: <HomePage />,
            },
            {
                path: "/login",
                element: <LoginPage />,
            },
            {
                path: "/registration",
                element: <RegistrationPage />,
            },
            {
                path: "/partners",
                element: <PartnersPage />,
            },
            {
                path: "/omos",
                element: <OmOsPage />,
            },
            {
                path: "/kontakt",
                element: <ContactPage />,
            },
            {
                path: "/business_central",
                element: <BusinessCentralPage />,
            },
            {
                path: "/rackbeat",
                element: <RackbeatPage />,
            },
            {
                path: "/economic",
                element: <EconomicPage />,
            },
            {
                path: "/dinero",
                element: <DineroPage />,
            },
            {
                path: "/navision",
                element: <NavisionPage />,
            },
            {
                path: "/business_central_kundeportal",
                element: <BusinessCentralKundeportalPage />,
            },
            {
                path: "/business_central_payments",
                element: <BusinessCentralPaymentsPage />,
            },
            {
                path: "/economic_kundeportal",
                element: <EconomicKundeportalPage />,
            },
            {
                path: "/economic_payments",
                element: <EconomicPaymentsPage />,
            },
            {
                path: "/priser",
                element: <PricingPage />,
            },
            {
                path: "/databehandleraftale",
                element: <DatabehandleraftalePage />,
            },
            {
                path: "/abonnementsvilkaar",
                element: <AbonnementsvilkårPage />,
            },
        ],
    },
]);
