import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Img04 from '../assets/business_kunda_online.png';
import Img03 from '../assets/business_kunda_third.svg';
import FooterTable from './FooterTable';

const BusinessCentralKundeportalTable = () => {
	const [infoData, setInfoData] = useState([]);

	useEffect(() => {
		const fetchInfoText = async (article, app, DK) => {
			try {
				const response = await axios.get(
					`https://engine.netsupport.dk:7080/account/v1/getinfotext?article=${article}&app=${app}&Language=${DK}`
				);
				return response.data.memo;
			} catch (error) {
				console.error('Error fetching info text:', error.message);
				throw error;
			}
		};

		const parameterSets = [
			{ article: 'bc.4.1', app: 'WEB', DK: 'DK' },
			{ article: 'bc.4.2', app: 'WEB', DK: 'DK' },
			{ article: 'bc.4.3', app: 'WEB', DK: 'DK' }
		];

		const fetchDataForAllParameters = async () => {
			const fetchPromises = parameterSets.map((params) =>
				fetchInfoText(params.article, params.app, params.DK)
			);
			const results = await Promise.all(fetchPromises);
			setInfoData(results);
		};

		fetchDataForAllParameters();
	}, []);

	return (
		<div className="text-[#2D838E] pt-20 md:pt-28 lg:pt-40">
			{/* hero part */}
			<div className="mb-4">
				<div className="hero lg:hidden">
					<div className="hero-content flex-col lg:flex-row-reverse">
						{/* <h1 className="text-4xl text-center font-bold leading-tight">
							{infoData[0]}
						</h1> */}
						<div className="flex-auto flex items-center flex-col">
							{/* <img src={Img01} className="max-w-[90%] md:max-w-[60%]" alt="" /> */}
							{/* <pre className="my-8 py-1">{infoData[1]}</pre> */}
						</div>
					</div>
				</div>
			</div>

			{/* third part */}
			<div className="pb-16 mb-4">
				<div className="hero hidden lg:block max-w-screen-xl mx-auto">
					<div className="hero-content pb-0 flex-col justify-between lg:flex-row-reverse">
						<img src={Img03} className="flex-auto max-w-[35%]" alt="" />
						<div className="flex-auto max-w-[50%]">
							<h1 className="text-4xl font-bold leading-tight">
								bcReminder for Business Central
							</h1>
							<pre className="my-8">
								Optimer likviditeten med ‘bcReminder for Business Central’, en
								vigtig tilføjelse, designet til at strømline din
								faktureringsproces.
							</pre>
							<pre className="my-4">
								Fuld integreret med Business Central, overvåger bcReminder alle
								debitorkonti dagligt, og identificerer forfaldne, ubetalte
								fakturaer. Når de foruddefinerede forfaldsdage overskrides,
								sendes der automatisk emails for at sikre at dine kunder er
								opmærksomme på forfaldne faktura.
							</pre>
							<pre className="my-2 font-semibold">Nøglefunktioner:</pre>
							<ul className=" list-disc ml-6">
								<li> Selekter debitorerne som skal overvåges.</li>
								<li> Angiv forfaldsdage for reminders</li>
								<li> Angiv tekster på flere sprog, afhængigt af debitorland</li>
								<li> Automatisk forsendele af emails</li>
							</ul>
							<pre className="mt-3 font-semibold">Problemfri integration:</pre>
							<pre>
								bcReminder integrerer problemfrit med Business Central og
								forbedrer din likviditet.
							</pre>
							<pre className="mt-3 font-semibold">
								Giv dine kunder adgang til Business Central kundeportalen
							</pre>
							<pre>
								Når du udvider med ‘bcPortal for Business Central’, kan du give
								dine kunder adgang til alle deres faktura, og alle deres
								registrerede indbetalinger.
							</pre>
							<pre>
								Linket, med login og password sendes med bcReminder, og dine
								kunder kan straks kontrollere om alle indbetalinger er
								registreret, og de kan se og downloade deres originale Business
								Central Faktura.
							</pre>
						</div>
					</div>
				</div>
			</div>

			{/* fourth part */}
			<div className="bg-[#e0eced] dark:bg-[#272f44] py-10 mb-4">
				<div className="max-w-screen-xl mx-auto hero">
					<div className="hero-content flex-col lg:flex-row lg:gap-28">
						<img src={Img04} className="flex-1 lg:max-w-md w-full" alt="" />
						<div className="flex-1 mt-3 lg:mt-0">
							<h1 className="text-4xl font-bold leading-tight">
								bcPortal for Business Central
							</h1>
							<pre className="pt-3">
								Gør det nemt for din bogholderi afdeling, og for dine kunder,
								giv dine kunder online adgang til deres egne faktura og
								indbetalingshistorik.
							</pre>
							<pre>
								Faktura kan downloades som PDF. bcPortal kan tilføjes til alle
								Business Central installationer.
							</pre>
							<pre className="mt-3 font-semibold">Nøglefordele:</pre>
							<ul className=" list-disc ml-6">
								<li>Du bestemmer, hvilke debitorer har adgang til systemet.</li>
								<li>URL og login/password kan nemt emailes til dine kunder.</li>
								<li>Du kan altid trække adgang tilbage.</li>
								<li>bcPortal er altid fuldt opdateret med Business Central.</li>
							</ul>
							<pre className="mt-3 font-semibold">Problemfri integration:</pre>
							<pre>
								bcReminder integrerer problemfrit med Business Central og
								forbedrer din likviditet.
							</pre>
						</div>
					</div>
				</div>
			</div>
			<FooterTable />
		</div>
	);
};

export default BusinessCentralKundeportalTable;
